<template>
  <b-form-group :class="['content-filter', wrapperClasses, {'highlight': highlight}, 'position-relative']">
    <b-input-group :size="size">
      <b-input-group-prepend v-if="prependIcon !== '' || prependText !== '' || prependSegments">
        <b-input-group-text v-if="prependIcon !== '' || prependText !== ''">
          <fluency-icon v-if="prependIcon !== ''"
                        :type="prependIcon"
                        :class="{'mr-3': prependText !== '' }" />
          {{prependText}}
        </b-input-group-text>
        <select-available-segments v-else-if="prependSegments" :reload-accounts="true"/>
      </b-input-group-prepend>

      <b-input :value="textFilter"
               @update="updateFilterText($event)"
               class="filter-input"
               data-qa="contentFilter-input"
               :placeholder="loading ? '' : placeholder"
               :autofocus="autofocus"
               :debounce="debounce"
               @focus="$emit('focus')"
               @blur="$emit('blur')"
               @keyup.enter.prevent="addTerm"></b-input>

      <b-input-group-append v-if="tmp.length > 0">
        <b-btn v-if="clearIcon !== '' || clearText !== ''"
               variant="dark"
               class="px-3 rounded-right"
               @click="clearFilter($event)">
          <fluency-icon v-if="clearIcon !== ''" :type="clearIcon" />
          {{clearText}}
        </b-btn>
      </b-input-group-append>
    </b-input-group>

    <div v-if="multiSupport" class="h4 mt-2">
      <span v-for="(arr, arrIndex) in tmpArray"
            :key="arrIndex"
            class="badge badge-secondary badge-pill pl-3 mr-2">
        {{arr}}
        <b-btn class="p-0"
               size="sm"
               variant="secondary"
               @click="removeTerm(arr)">
          <fluency-icon type="x"></fluency-icon>
        </b-btn>
      </span>
    </div>
    <div v-if="loading" class="position-absolute" style="top: 50%; left: 50%;transform: translate(-50%, -50%); z-index:9">
      <fluency-loader dots color style="position: relative; top: 2px;" />
    </div>
  </b-form-group>
</template>

<script>
import _ from 'lodash'
import SelectAvailableSegments from '../../test/selectAvailableSegments'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'

export default {
  name: 'content-filter',
  components: { FluencyLoader, SelectAvailableSegments },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Filter'
    },
    minLength: {
      type: Number,
      default: 1 // if you change this default then you need to do the backspace test to make sure the text doesn't get blanked out as you are backspacing -sng
    },
    prependIcon: {
      type: String,
      default: ''
    },
    prependText: {
      type: String,
      default: ''
    },
    prependSegments: {
      type: Boolean,
      default: false
    },
    clearIcon: {
      type: String,
      default: 'clear'
    },
    clearText: {
      type: String,
      default: ''
    },
    lowercase: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    multiSupport: {
      type: Boolean,
      default: false
    },
    multiArray: {
      type: Array,
      default: () => { return [] }
    },
    debounce: {
      type: Number,
      default: 250
    },
    loading: {
      type: Boolean,
      default: false
    },
    wrapperClasses: {
      type: String,
      default: 'mb-0'
    }
  },
  data () {
    return {
      textFilter: '',
      tmp: '',
      tmpArray: [],
      loadingHold: ''
    }
  },
  mounted () {
    this.textFilter = this.value
    this.$setCompat(this, 'tmpArray', this.multiArray)
  },
  watch: {
    textFilter: function (val) {
      val = this.lowercase ? val.toLowerCase() : val

      this.submitForEmit(val)
    },
    value: {
      handler (val) {
        if (val !== this.textFilter) {
          this.textFilter = this.value
          this.tmp = this.value
        }
      },
      immediate: true
    },
    tmpArray: {
      handler: function (val) {
        this.$emit('multi-update', val)
      },
      deep: true
    },
    loading (val, old) {
      if (old && !val && this.loadingHold && this.loadingHold !== this.textFilter) {
        this.updateFilterText(this.loadingHold)
        this.loadingHold = ''
      }
    }
  },
  methods: {
    addTerm (e) {
      if (this.multiSupport && this.tmp) {
        const term = this.tmp.toLowerCase()

        if (!this.tmpArray.includes(term)) {
          const arr = this.tmpArray
          arr.push(term)
          this.$setCompat(this, 'tmpArray', arr)
        }

        this.clearFilter()
      }
      this.$emit('keyupEnter')
    },
    removeTerm (term) {
      const arr = this.tmpArray

      arr.splice(arr.indexOf(term), 1)

      this.$setCompat(this, 'tmpArray', arr)
    },
    updateFilterText (val) {
      if (this.loading) {
        this.loadingHold = val
        return
      }
      this.$setCompat(this, 'tmp', val)
      this.$setCompat(this, 'textFilter', val)
    },
    clearFilter () {
      this.$setCompat(this, 'tmp', '')
      this.$setCompat(this, 'textFilter', '')
      this.$emit('clear')
    },
    submitForEmit (val) {
      if (val.length < this.minLength) {
        val = ''
      }

      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-input {
    box-shadow: none;
  }
</style>
